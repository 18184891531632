import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_img_dialog = _resolveComponent("select-img-dialog")!

  return (_openBlock(), _createBlock(_component_select_img_dialog, {
    visible: _ctx.config.visible,
    url: _ctx.config.url,
    "task-options": _ctx.designTaskOptions,
    type: _ctx.SELECT_IMAGE_SOURCE_ENUM.PLUGIN_COLLECT,
    "source-page": _ctx.config.sourcePage,
    "domain-name": _ctx.config.domainName
  }, null, 8, ["visible", "url", "task-options", "type", "source-page", "domain-name"]))
}