
import { defineComponent, ref } from 'vue';
import SelectImgDialog from '@/modules/task-manage/design-task/views/detail/components/upload-design-dialog/index.vue';
import {
  getDesignTaskOptions,
  getFileBase64,
} from '@/modules/task-manage/design-task/api/index';
import {
  IGetDesignTaskOptionsRes,
} from '@/modules/task-manage/design-task/api/types';
import {
  SELECT_IMAGE_SOURCE_ENUM,
} from '@/modules/task-manage/design-task/constant';
import { useRoute } from 'vue-router';
import { random } from 'lodash-es';
import { uploadFile } from '@/api/open';
import { ElMessageBox } from 'element-plus';

export default defineComponent({
  components: {
    SelectImgDialog,
  },
  setup() {
    const route = useRoute();
    const designTaskOptions = ref<IGetDesignTaskOptionsRes[]>([]);
    const getDesignOptions = async () => {
      try {
        const { data } = await getDesignTaskOptions();
        designTaskOptions.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    getDesignOptions();
    const config = ref({
      url: '',
      sourcePage: '',
      domainName: '',
      visible: false,
    });
    const init = async () => {
      const url = route.query.url as string;
      const source = route.query.source as string;
      const domain = route.query.domain as string;
      // 将图片处理为base64后上传oss
      if (url) {
        const res = await getFileBase64({
          url,
        });
        // 将base64转换为files对象
        const arr: any = res.data.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const type = mime.split('/')[1];
        const incluedeTypes = [
          'jpg',
          'jpeg',
          'png',
        ];
        if (!incluedeTypes.includes(type)) {
          try {
            await ElMessageBox.alert(`${type}格式的文件不支持采集`, '提示');
          } finally {
            window.close();
          }
          return;
        }
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        // eslint-disable-next-line no-plusplus
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const formData = new FormData();
        const file = new File([u8arr], `${Date.now()}_${random(1, 9999)}.${type}`, { type: mime });
        formData.append('files', file, file.name);
        const { data } = await uploadFile(formData);
        config.value.url = data[0].url;
        config.value.domainName = domain;
        config.value.sourcePage = source;
        config.value.visible = true;
      }
      getDesignOptions();
    };
    init();
    return {
      config,
      designTaskOptions,
      SELECT_IMAGE_SOURCE_ENUM,
    };
  },
});

